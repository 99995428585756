import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './CalculoFatura.css';

function CalculoFatura() {
  const [mensalidade, setMensalidade] = useState('');
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [resultado, setResultado] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [animateResult, setAnimateResult] = useState(false);

  const dataInicialRef = useRef(null);
  const dataFinalRef = useRef(null);

  useEffect(() => {
    if (showResult) {
      setTimeout(() => setAnimateResult(true), 50);
    } else {
      setAnimateResult(false);
    }
  }, [showResult]);

  const calcularValorProporcional = (e) => {
    e.preventDefault();
    
    const inicio = new Date(dataInicial);
    const fim = new Date(dataFinal);
    
    // Adiciona um dia à data final para incluir o último dia no cálculo
    fim.setDate(fim.getDate() + 1);
    
    // Calcula a diferença em dias
    const diffDias = Math.floor((fim - inicio) / (1000 * 60 * 60 * 24));
    
    const valorMensal = parseFloat(mensalidade);
    const valorDiario = valorMensal / 30;
    
    // Calcula o valor proporcional
    const valorProporcional = valorDiario * diffDias;
    
    setResultado({
      dias: diffDias,
      valorDiario: valorDiario.toFixed(2),
      valor: valorProporcional.toFixed(2)
    });

    setShowResult(true);
  };

  const openDatePicker = (ref) => {
    if (ref.current) {
      ref.current.showPicker();
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="header-principal">
          <Link to="/" className="home-button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFFFFF" width="24px" height="24px">
              <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/>
            </svg>
          </Link>
          {showResult ? (
            <div className={`resultado-content ${animateResult ? 'show' : ''}`}>
              <h1>Cálculo de Valor na Próxima Fatura</h1>
              <div className="resultado">
                <p>Período: {resultado.dias} dias</p>
                <p>Valor Diário: R$ {resultado.valorDiario}</p>
                <p>Valor Proporcional: R$ {resultado.valor}</p>
              </div>
              <img 
                src="/images/quick-fatura-logo.png" 
                alt="Logo Quick" 
                className={`App-logo ${animateResult ? 'show' : ''}`} 
              />
            </div>
          ) : (
            <div className="instrucoes-content">
              <h2>INSTRUÇÕES DE CÁLCULO</h2>
              <p className="instrucao-data"><strong>Data Inicial:</strong> Vencimento da última fatura</p>
              <p className="instrucao-data"><strong>Data Final:</strong> Dia anterior ao próximo vencimento</p>
              <p className="exemplos-preenchimento">
                Exemplos de preenchimento:<br/>
                Se a nova fatura vence no dia 01, registre a Data Final como 30.<br/>
                Se a nova fatura vence no dia 16, registre a Data Final como 15.
              </p>
            </div>
          )}
        </div>
        <form onSubmit={calcularValorProporcional}>
          <div className="input-valor">
            <input 
              type="number" 
              value={mensalidade} 
              onChange={(e) => setMensalidade(e.target.value)}
              required
              placeholder="Valor do plano mensal (R$)"
            />
          </div>
          <div className="input-datas">
            <div className="input-data">
              <input 
                ref={dataInicialRef}
                type="date" 
                value={dataInicial} 
                onChange={(e) => setDataInicial(e.target.value)}
                required
                placeholder="Data Inicial"
              />
              <span className="calendar-icon" onClick={() => openDatePicker(dataInicialRef)}></span>
            </div>
            <div className="input-data">
              <input 
                ref={dataFinalRef}
                type="date" 
                value={dataFinal} 
                onChange={(e) => setDataFinal(e.target.value)}
                required
                placeholder="Data Final"
              />
              <span className="calendar-icon" onClick={() => openDatePicker(dataFinalRef)}></span>
            </div>
          </div>
          <button type="submit">Calcular</button>
        </form>
      </header>
    </div>
  );
}

export default CalculoFatura;