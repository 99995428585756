import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './CalculoFatura.css';

function CalculoFidelidade() {
  const [dataAdesao, setDataAdesao] = useState('');
  const [dataCancelamento, setDataCancelamento] = useState('');
  const [tipoContrato, setTipoContrato] = useState('12');
  const [resultado, setResultado] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [animateResult, setAnimateResult] = useState(false);

  const dataAdesaoRef = useRef(null);
  const dataCancelamentoRef = useRef(null);

  useEffect(() => {
    if (showResult) {
      setTimeout(() => setAnimateResult(true), 50);
    } else {
      setAnimateResult(false);
    }
  }, [showResult]);

  const calcularFidelidade = (e) => {
    e.preventDefault();
    
    const adesao = new Date(dataAdesao);
    const cancelamento = new Date(dataCancelamento);
    
    // Calcula a diferença em meses
    const diffMeses = (cancelamento.getFullYear() - adesao.getFullYear()) * 12 + 
                      (cancelamento.getMonth() - adesao.getMonth());
    
    const periodoContrato = parseInt(tipoContrato);
    const multaTotal = periodoContrato === 12 ? 460 : 920;
    const mesesFaltantes = Math.max(periodoContrato - diffMeses, 0);
    const valorPorMes = multaTotal / periodoContrato;
    const valorFidelidade = mesesFaltantes * valorPorMes;
    
    setResultado({
      mesesUtilizados: diffMeses,
      mesesFaltantes: mesesFaltantes,
      valorFidelidade: valorFidelidade.toFixed(2),
      multaTotal: multaTotal.toFixed(2),
      valorPorMes: valorPorMes.toFixed(2)
    });

    setShowResult(true);
  };

  const openDatePicker = (ref) => {
    if (ref.current) {
      ref.current.showPicker();
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="header-principal">
          <Link to="/" className="home-button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFFFFF" width="24px" height="24px">
              <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/>
            </svg>
          </Link>
          {showResult ? (
            <div className={`resultado-content ${animateResult ? 'show' : ''}`}>
              <h1>Cálculo de Fidelidade</h1>
              <div className="resultado">
                <p>Meses utilizados: {resultado.mesesUtilizados}</p>
                <p>Meses faltantes: {resultado.mesesFaltantes}</p>
                <p>Valor por mês: R$ {resultado.valorPorMes}</p>
                <p>Multa total: R$ {resultado.multaTotal}</p>
                <p>Valor da fidelidade: R$ {resultado.valorFidelidade}</p>
              </div>
              <img 
                src="/images/quick-fatura-logo.png" 
                alt="Logo Quick" 
                className={`App-logo ${animateResult ? 'show' : ''}`} 
              />
            </div>
          ) : (
            <div className="instrucoes-content">
              <h2>INSTRUÇÕES DE CÁLCULO</h2>
              <p className="instrucao-data"><strong>Data Adesão:</strong> Data de início do contrato</p>
              <p className="instrucao-data"><strong>Data Cancelamento:</strong> Data desejada para cancelamento</p>
              <p className="instrucao-data"><strong>Tipo de Contrato:</strong> Selecione 12 ou 24 meses</p>
            </div>
          )}
        </div>
        <form onSubmit={calcularFidelidade}>
          <div className="input-datas">
            <div className="input-data">
              <input 
                ref={dataAdesaoRef}
                type="date" 
                value={dataAdesao} 
                onChange={(e) => setDataAdesao(e.target.value)}
                required
                placeholder="Data Adesão"
              />
              <span className="calendar-icon" onClick={() => openDatePicker(dataAdesaoRef)}></span>
            </div>
            <div className="input-data">
              <input 
                ref={dataCancelamentoRef}
                type="date" 
                value={dataCancelamento} 
                onChange={(e) => setDataCancelamento(e.target.value)}
                required
                placeholder="Data Cancelamento"
              />
              <span className="calendar-icon" onClick={() => openDatePicker(dataCancelamentoRef)}></span>
            </div>
          </div>
          <div className="input-tipo-contrato">
            <select
              value={tipoContrato}
              onChange={(e) => setTipoContrato(e.target.value)}
              required
            >
              <option value="12">12 meses</option>
              <option value="24">24 meses</option>
            </select>
          </div>
          <button type="submit">Calcular</button>
        </form>
      </header>
    </div>
  );
}

export default CalculoFidelidade;