import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

function Home() {
  return (
    <div className="Home">
      <h5>Suporte de Cálculos</h5>
      <div className="button-container">
        <Link to="/calculo-fatura" className="button">
          Cálculo de Fatura
        </Link>
        <Link to="/calculo-fidelidade" className="button button-fidelidade">
          Cálculo Fidelidade
        </Link>
      </div>
    </div>
  );
}

export default Home;